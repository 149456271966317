<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import Level from "@/components/loyalty/new-level"

import {
  loyaltyLevelsMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Go Layalty Levels",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    Level
  },
  data() {
    return {
      title: this.$t("loyalty_levels.title"),
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      levels:[],
      confirmDelete:false,
      level: {},
      empty_list_config:{
          "title" : this.$t("loyalty_levels.empty_list"),
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      empty_config:{
          "title" : this.$t("loyalty_levels.empty_list"),
          "subtitle" : this.$t("loyalty_levels.empty_list_subtitle"),
          "buttonText" : this.$t("loyalty_levels.new"),
          icon: "fa-cubes"
      },
        showNewLevelModal: false,
        isLocal: window.config.env == 'dev',
        query:''
    };
  },
  created(){
		this.debounceLevels = _debounce(this.searchLevels.bind(this), 1000);
	},
	mounted(){
		this.loadLevels();
  },
  methods:{
    ...loyaltyLevelsMethods,
    
    onRemoveLevel(data){
      this.level = data;
      this.confirmDelete = true;
    },
    onConfirmRemoveLevelClicked(){
      let loader = this.$loading.show();
      this.currentPage=1;
        this.loadLevels();
      this.$notify({ type: 'success', text: this.$t('loyalty_levels.delete_level_success'),title:  this.$t('loyalty_levels.title') });
      loader.hide();
    },

		loadLevels(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      const params={
					q: `where[project]=${localStorage.getItem("current_project")}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}
      this.getLoyaltyLevels(params).then((levels)=>{
        this.levels = levels.data;
        this.rows = levels.totalCount;
        this.isLoading = false;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('loyalty_levels.get_levels_error'),title:  this.$t('loyalty_levels.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      })  ;
      
      this.isLoading = false;
      loader.hide();
    },
    searchLevels(query){
      this.query = query;
			let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("memberships.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.levels=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getLoyaltyLevels(params).then((levels)=>{
          if(levels&& levels.data){
            this.levels= levels.data;
            this.rows= levels.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadLevels();  
      }
    },
		onLevelListPageClicked(){
			this.loadLevels();
		},	
    onEditLevelClicked(data){
      this.level = Object.assign({}, data);
      this.showNewLevelModal = true;
    },
    
    onResetSearchClicked(){
      this.query = '';
      this.loadLevels();
    },
    onNewLevelClicked(){
      this.level = {}
      this.showNewLevelModal = true
    },
    onConfirmNewLevelClicked(){

      if(this.$refs.refLevelComponent.onValidateLevel()){
        let loader = this.$loading.show();
        this.level.project = localStorage.getItem("current_project");

        if(this.level._id){
          this.updateLoyaltyLevel(this.level).then(()=>{  
            this.showNewLevelModal = false;
            this.loadLevels();
            this.$notify({ type: 'success', text: this.$t('loyalty_levels.update_loyalty_level_success'),title:  this.$t('loyalty_levels.title') });
          }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t('loyalty_levels.update_loyalty_level_error'),title:  this.$t('loyalty_levels.title') });
          }).finally(()=>{
            this.isLoading = false;
            loader.hide();
          });
        }else{
          this.createLoyaltyLevel(this.level).then(()=>{  
          this.showNewLevelModal = false;
          this.loadLevels();
          this.$notify({ type: 'success', text: this.$t('loyalty_levels.create_loyalty_level_success'),title:  this.$t('loyalty_levels.title') });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('loyalty_levels.create_loyalty_level_error'),title:  this.$t('loyalty_levels.title') });
        }).finally(()=>{
          this.isLoading = false;
          loader.hide();
        });
        }
        
      }
      
    },
    onToggleLevelStatus(data){
      let loader = this.$loading.show();
      const l = Object.assign({}, data);
      l.active = data.active == 1 ? 0 : 1;
      this.updateLoyaltyLevel(l).then(()=>{
        this.loadLevels();
        this.$notify({ type: 'success', text: this.$t('loyalty_levels.update_loyalty_level_success'),title:  this.$t('loyalty_levels.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('loyalty_levels.update_loyalty_level_error'),title:  this.$t('loyalty_levels.title') });
      }).finally(()=>{
        loader.hide();
      })
    } 
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceLevels($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-0"
                      @click="onNewLevelClicked"
                      v-if="!isLoading && levels.length>0">
                  <i class="mdi mdi-plus me-1"></i> {{ $t("loyalty_levels.new") }}
              </button>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && levels.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && levels.length == 0 && query ==''" @onButtonClicked="onNewLevelClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && levels.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{ $t("loyalty_levels.name") }}</th>
                      <th scope="col">{{ $t("loyalty_levels.points_accumulated") }}</th>
                      <th scope="col">{{ $t("loyalty_levels.members") }}</th>
                      <th scope="col">{{ $t("loyalty_levels.status") }}</th>
      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in levels" :key="data._id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditLevelClicked(data)">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                      </td>
                      <td>
												{{ data.active ==1 ? data.points : '-'}}
											</td>
                      <td>
												{{ data.members|| 0 }}
											</td>
											<td>
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.active}` == 1,
                          'bg-warning': `${data.active}` == 0}">
                            {{ data.active == 1 ? $t('loyalty_levels.status_active') : $t('loyalty_levels.status_inactive') }}
                        </span>
											</td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 interact" :class="data.active == 1 ? 'fa-pause' : 'fa-play'" v-on:click="onToggleLevelStatus(data)"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditLevelClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveLevel(data)" v-if="false"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onLevelListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveLevelClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{ $t("loyalty_levels.remove_loyalty_level") }}</p>
        </b-modal>
        <b-modal
          v-model = "showNewLevelModal"
          title-class="font-18"
          :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')"
          :title="level?._id ? $t('loyalty_levels.edit') : $t('loyalty_levels.new')"
          @ok.prevent="onConfirmNewLevelClicked">
          <Level :level="level" ref="refLevelComponent"></Level>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
</style>
