<script>
import { required } from "vuelidate/lib/validators";
import multiselect from 'vue-multiselect';
import {
	loyaltyProgramsMethods
} from "@/state/helpers";

export default {
	components: {
		multiselect
	},
	data() {
		return {
			submitted:false,
			loyaltyPrograms: [],
		};
	},
	validations: {
		level: {
			name: {
				required,
			},
			points: {
				required,
			},
			loyaltyProgram: {
				required,
			}
		},
	},
	methods: {
		...loyaltyProgramsMethods,

		onValidateLevel(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return false;
			}

			return true;
		},
		loadLoyaltyPrograms(){
			let loader = this.$loading.show();

			const params={
					q: `where[status]=1&where[project]=${localStorage.getItem("current_project")}&limit=50`,
				}
      this.getLoyaltyPrograms(params).then((loyaltyPrograms)=>{
        this.loyaltyPrograms = loyaltyPrograms.data;
				if(this.loyaltyPrograms.length == 1 && !this.level.loyaltyProgram){
					this.level.loyaltyProgram = this.loyaltyPrograms[0];
				}

				if(this.level.loyaltyProgram){
					this.level.loyaltyProgram = this.loyaltyPrograms.filter(lp => lp._id == this.level.loyaltyProgram)[0];
				}
        loader.hide();
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('loyalty_programs.get_loyalty_programs_error'),title:  this.$t('loyalty_programs.title') });
        this.isLoading = false;
        loader.hide();
      })
		}
  },
	mounted() {
		this.loadLoyaltyPrograms();
	},
	props: {
		level : {
			type: Object
		}
	},
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label>{{ $t("loyalty_levels.name") }}*</label>
					<input
						v-model="level.name"
						type="text"
						class="form-control"
						:placeholder="$t('loyalty_levels.name_placeholder')"
						:class="{
							'is-invalid': submitted && $v.level.name.$error,
						}"
					/>
					<div
						v-if="submitted && !$v.level.name.required"
						class="invalid-feedback">
						{{ $t("loyalty_levels.name_required") }}
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label>{{ $t("loyalty_levels.loyalty_program") }}*</label>
					<multiselect 
						v-model="level.loyaltyProgram"
						:options="loyaltyPrograms"
						:selectLabel="$t('common.select')"
						:deselectLabel="$t('common.deselect')"
						:placeholder="$t('loyalty_levels.loyalty_program_placeholder')"
						:multiple="false"
						track-by="_id" 
						label="name"
						:showNoResults="false"
						:class="{ 'is-invalid': submitted && $v.level.loyaltyProgram.$error}">
					</multiselect>
					<div
						v-if="submitted && !$v.level.loyaltyProgram.required"
						class="invalid-feedback">
						{{ $t("loyalty_levels.loyalty_program_required") }}
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label>{{ $t("loyalty_levels.points_accumulated") }}*</label>
					<b-form-input type="number" v-model="level.points"  :class="{
							'is-invalid': submitted && $v.level.points.$error,
						}" :placeholder="$t('loyalty_levels.points_accumulated_placeholder')" />
					<div
						v-if="submitted && !$v.level.points.required"
						class="invalid-feedback">
						{{ $t("loyalty_levels.points_required") }}
					</div>
				</div>
			</div>
		</div>
    <div class="row" v-if="false">
			<div class="col">
        <b-form-group
          class="mb-3"
          label-cols-sm="1"
          label-cols-lg="1"
          label="Color"
          label-for="color">
          <b-form-input
            v-model="level.color"
            class="form-control-color mw-100"
            id="color"
            type="color"
            name="color"
          ></b-form-input>
        </b-form-group>
			</div>
		</div>
	</div>
</template>

<style scoped>

</style>